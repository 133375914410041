import React, { Component } from 'react'
import { Link, graphql } from 'gatsby'
import SEO from '../components/seo'
import { Flex, Box } from '@rebass/grid'
import Copy from '../components/page-copy'
//import Title from '../components/page-title';
import Overview from '../components/page-overview'
import Row from '../components/page-content-row'
import PageRangeGrid from '../components/page-range-grid'

import Image1 from '../images/designs/happyhaus-1G-form-mint-warm.jpg'
import Image2 from '../images/designs/happyhaus-1C-form-warm-context.jpg'
import Image3 from '../images/designs/happyhaus-1E-form-light.jpg'
import Image4 from '../images/designs/happyhaus-1D-form-light.jpg'

const ogImages = [
  `https://happyhaus.com.au${Image1}`,
  `https://happyhaus.com.au${Image2}`,
  `https://happyhaus.com.au${Image3}`,
  `https://happyhaus.com.au${Image4}`,
]

class Page extends Component {
  render() {
    const { data } = this.props
    const homesData = data.allHomesJson.edges

    return (
      <>
        <SEO
          title="Designs"
          description="Our collection is designed to suit block widths of 10m, 12.5m and 14m—each design named accordingly in both single and double-story homes. "
          images={ogImages}
        />
        <Row>
          <Flex>
            <Box width={1} px={2} mr={[0, '20%']}>
              <Overview align="left">
                <p>
                  Our collection is designed to suit block widths of 10m, 12.5m
                  and 14m—each design named accordingly in both single and
                  double-story homes.
                </p>
              </Overview>
              <Copy>
                <p>
                  <Link to="/designs-single">Single Storey</Link> &nbsp;{' '}
                  <Link to="/designs-double">Double Storey</Link>
                </p>
              </Copy>
            </Box>
          </Flex>
        </Row>
        <Row>
          <PageRangeGrid range={homesData} />
        </Row>
      </>
    )
  }
}

export default Page

export const query = graphql`
  query {
    allHomesJson(sort: { fields: [storey, title], order: DESC }) {
      edges {
        node {
          id
          storey
          title
          stats {
            bed
            bath
            car
            price
            size
          }
          summary
          thumb
        }
      }
    }
  }
`
